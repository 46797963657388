<template>
  <section :class="$style.root">
    <div v-if="archiveNewsTop3?.length" :class="$style.container">
      <div v-if="minScreenLg" :class="$style.pc_title_container">
        <MoleculesConsumerHeading title="News" variant="sub" align="left" />
        <AtomsConsumerLink url="/news">
          <MoleculesConsumerButtonWithArrowRight variant="secondary"
            >アーカイブを見る</MoleculesConsumerButtonWithArrowRight
          >
        </AtomsConsumerLink>
      </div>
      <MoleculesConsumerHeading v-else title="News" variant="sub" align="center" />

      <div :class="$style.news_container">
        <AtomsConsumerLink
          v-for="item in archiveNewsTop3"
          :key="item.document_id"
          :class="$style.news"
          :url="`/news/${item.slug}`"
        >
          <p :class="$style.date">{{ formatUnixTime(item.release_date, "YYYY.MM.DD") }}</p>
          <p :class="$style.title">{{ item.title }}</p>
        </AtomsConsumerLink>
      </div>

      <AtomsConsumerLink v-if="!minScreenLg" url="/news" :class="$style.sp_view_more">
        <MoleculesConsumerButtonWithArrowRight variant="secondary"
          >アーカイブを見る</MoleculesConsumerButtonWithArrowRight
        >
      </AtomsConsumerLink>
    </div>
  </section>
</template>

<script setup lang="ts">
import { formatUnixTime } from "@tential/ec-gql-schema/utils/functions/date"
import { NewsGroupFragment, PublicNewsGroupFindOneQueryVariables } from "~/types/type.generated"
import { usePublicNewsGroupFindOneQuery } from "~/gql/urql.generated"

const props = defineProps<{
  newsGroupId?: string
}>()

const archiveNewsTop3 = ref<NewsGroupFragment["active_news"]>()

const newsGroupVariablesRef = ref<PublicNewsGroupFindOneQueryVariables>({
  filter: {
    document_id: props.newsGroupId || "",
    is_status: true,
  },
})
const { executeQuery } = await usePublicNewsGroupFindOneQuery({
  requestPolicy: "cache-first",
  variables: newsGroupVariablesRef,
  pause: true,
})

useAsyncData("usePublicNewsGroupFindOneQuery", async () => {
  if (executeQuery().isPaused) await executeQuery().resume()
  const { data: record } = await executeQuery()
  archiveNewsTop3.value = (record?.value?.publicNewsGroupFindOne?.active_news || []).slice(0, 3)
})

const { width: windowWidth } = useWindowSize()
const minScreenLg = computed(() => windowWidth.value >= 1024)
</script>

<style scoped module lang="scss">
.root {
  @include container-lg;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    @include lg {
      flex-direction: row;
      gap: 10.5rem;
      justify-content: space-between;
      align-items: start;
    }

    .pc_title_container {
      display: flex;
      flex-direction: column;
      gap: 3.125rem;
    }

    .news_container {
      display: flex;
      flex-direction: column;

      @include lg {
        flex: 1;
      }

      .news {
        display: flex;
        gap: 1.25rem;
        justify-content: flex-start;
        box-sizing: border-box;
        border-top: $primary-20 solid 1px;
        padding: 1.25rem 0;
        font-size: $font-size-12;
        cursor: pointer;

        &:last-child {
          border-bottom: $primary-20 solid 1px;
        }

        @include lg {
          padding: 1.5rem 0;
          font-size: $font-size-15;
          gap: 2.25rem;
        }

        .date {
          color: $primary;
        }

        .title {
          color: $black-90;
        }
      }
    }
  }
}
</style>
